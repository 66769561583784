import type {
  Client,
  OptimizelyDecision,
  OptimizelyUserContext,
  UserAttributes,
} from '@optimizely/optimizely-sdk';
import { createInstance } from '@optimizely/optimizely-sdk';

import { datadogLog, datadogRum } from '@ecp/utils/logger';
import type { PersistentStorage } from '@ecp/utils/storage';
import { storageProxy } from '@ecp/utils/storage';
import { deviceInformation } from '@ecp/utils/web';

import { env } from '@ecp/env';

import type { Events } from './constants';
import { FeatureFlags, FlagVariables, moduleName } from './constants';
import type {
  AudienceAttribute,
  CombinedFlagValues,
  FeatureFlagType,
  FlagVariableTypes,
  LogLevel,
} from './types';

interface State {
  userId: string;
}

/** Backup storage mechanism to initialize and persist local state. Must be updated each time state gets updated. */
let storage: PersistentStorage | undefined;
let initialized: boolean;

let state: State = {
  userId: '',
};

export let optimizely: Client | null;

const getDeviceAttributes = (): string => {
  const { browser, device, os } = deviceInformation;

  return `${device.type ? device.type : 'desktop'}_${os.name}_${browser.name}`;
};

/**
 * @param params.sdkKey Optimizely SDK key
 * @param params.storage Storage mechanism to initialize and persist related parameters for feature flags
 * @param params.logLevel set log level to configure the log level threshold
 * ERROR - Events that prevent feature flags or experiments from functioning correctly (for example, invalid datafile in initialization and invalid feature keys) are logged. The user can take action to correct.
 * WARNING - Events that don't prevent feature flags or experiments from functioning correctly, but can have unexpected outcomes (for example, future API deprecation, logger or error handler are not set properly) are logged.
 * INFO - Events of significance (for example, decision started, decision succeeded, tracking started, and tracking succeeded) are logged. This is helpful in showing the lifecycle of an API call.
 * DEBUG - Any information related to errors that can help us debug the issue (for example, the feature flag is not running, user is not included in the rollout) are logged.
 * @see https://docs.developers.optimizely.com/feature-experimentation/docs/customize-logger-javascript
 */
export const initialize = async (params: {
  sdkKey: string;
  storage?: typeof storage;
  logLevel?: LogLevel;
}): Promise<
  | {
      success: boolean;
      reason?: string | undefined;
    }
  | undefined
> => {
  if (initialized) return undefined;
  if (params.storage) {
    storage = params.storage;
    const savedState = { ...(storage.getItem(moduleName) as unknown as State) };
    state = storageProxy(savedState, storage, moduleName);
  }

  if (!state.userId) {
    let generatedId = datadogRum.getInternalContext()?.session_id;
    if (!generatedId) {
      // Use two 32-bit integers
      const array = new Uint32Array(2);
      window.crypto.getRandomValues(array);
      // This simulates a 64-bit integer
      const randomValue = array[0] * 4294967296 + array[1];
      generatedId = `optimizely-${randomValue.toString(16)}`;
    }
    state.userId = generatedId;
  }

  /**
   * Initialization of the Optimizely SDK so that we can reuse it later.
   * We will set autoUpdate to false because otherwise it will make a network
   * request on a set interval every millisecond and we don't want that.
   *
   * Essentially how Optimizely works is that there is one large config file,
   * or datafile, that gets downloaded when we call the createInstance method
   * on the OptimizelySDK, then from there any other called to the OptimizelySDK
   * just reads from that.
   */

  // optimizely proxy disabled for sales until they want to use it.
  // optimizely proxy is enabled for costco bundle sales qa
  const proxyEnabled = !!env.optimizely.proxyEnabled;

  optimizely = createInstance({
    sdkKey: params.sdkKey,
    datafileOptions: proxyEnabled
      ? {
          autoUpdate: false,
          urlTemplate: `/datafiles/${params.sdkKey}.json`,
        }
      : { autoUpdate: false },
    errorHandler: {
      handleError(error) {
        let errorMessage = '';
        if (error?.message && error?.stack && error.stack.includes(error.message)) {
          errorMessage = error.stack;
        } else {
          errorMessage =
            error.message || error.stack
              ? `${error?.message} -- ${error?.stack}`
              : 'Optimizely create instance failed';
        }
        datadogLog({
          logType: 'warn',
          message: `${errorMessage} | optimizelyUserIdFromSessionStorage: ${state.userId} | initialize(): ${state.userId}`,
          context: {
            logOrigin: 'libs/utils/flags/src/featureFlags/featureFlags.ts',
            functionOrigin: 'initialize/OptimizelySDK',
          },
          error,
        });
      },
    },
    logLevel: params.logLevel,
    // these are defaults, but stops Optimizely from complaining with warning in console
    eventBatchSize: 10,
    eventFlushInterval: 1000,
  });

  initialized = true;

  return optimizely?.onReady({ timeout: 40000 });
};

export let optimizelyUserAttributes: UserAttributes = {
  device: getDeviceAttributes(),
  testAudience: env.optimizely.testAudience,
};

export let user: OptimizelyUserContext | null | undefined;

/**
 * There are a few oddities with optimizely.
 * - Nothing about the SDK is async even though it has to download a whole datafile.
 * - Also, there's the issue of having to create a user in optimizley to make a decision about a certain key.
 * There is a function called getVariation which takes in a key. The key, however, is a randomly generated
 * key from Optimimizely, NOT the one you gave it. Currently, the method you see here is the only way to get
 * the value of the variation i.e. on | off.
 */

export const flagValues: FeatureFlagType = Object.values(FeatureFlags).reduce((acc, key) => {
  acc[key] = false;

  return acc;
}, {} as FeatureFlagType);

export const flagVariables: FlagVariableTypes = Object.keys(FlagVariables).reduce((acc, key) => {
  acc[key] = Object.values(FlagVariables[key]).reduce((itemAcc, itemVal) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    itemAcc[itemVal] = undefined;

    return itemAcc;
  }, {} as FlagVariableTypes[keyof FlagVariableTypes]);

  return acc;
}, {} as FlagVariableTypes);

let allFlagDecisions: { [key: string]: OptimizelyDecision };

export const getAllFlagDecision = (): { [key: string]: OptimizelyDecision } => {
  return allFlagDecisions;
};

const updateFeatureFlags = (): void => {
  if (!optimizely) {
    console.warn('Optimizely unavailable');

    return;
  }

  const allDecisions = user?.decideAll() || {};
  allFlagDecisions = allDecisions;

  Object.values(FeatureFlags).forEach((key) => {
    if (!allDecisions[key] || !optimizely) {
      flagValues[key] = false;
    } else {
      const isOnOffFlag = ['on', 'off'].includes(allDecisions[key].variationKey);
      const decision = isOnOffFlag
        ? allDecisions[key].variationKey === 'on'
        : allDecisions[key].variationKey;

      const result = allDecisions[key].enabled ? decision : false;
      // TODO: FeatureFlagReturnType getting parsed as boolean. It should
      // also include the overrides from FeatureFlagReturnOverrides. Language
      // server seems to be working, so it should show the desired types per-key
      // in use across the app.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      flagValues[key] = (result ?? false) as CombinedFlagValues;
    }
  });

  Object.entries(FlagVariables).forEach(([key, variables]) => {
    Object.values(variables).forEach((variable) => {
      const variableText = allDecisions[key]?.variables[
        variable
      ] as FlagVariableTypes[keyof FlagVariableTypes][keyof FlagVariableTypes[keyof FlagVariableTypes]];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      flagVariables[key][variable] = variableText || '';
    });
  });
};

export const updateUserAttributes = (attributes: AudienceAttribute): void => {
  optimizelyUserAttributes = {
    ...optimizelyUserAttributes,
    ...attributes,
  };
  user = optimizely?.createUserContext(state.userId, optimizelyUserAttributes);
  updateFeatureFlags();
};

export const trackEvent = (event: Events): void => {
  user?.trackEvent(event);
};

export const getOptimizelyUserId = (): string => state.userId;

/**
 * TODO: figure out w/ SAPI if/what we need to send for active experiments
 *
 * send to SAPI as: { 'analytics.variants': ['', ''] }
 *
 */
