/** Enterprise API header names. */
export const enum Headers {
  API_KEY = 'afe-api-key',
  /** DAL api auth key */
  AUTH_KEY = 'afe-auth-key',
  /** When calling api's we must send an experience id or a partner id, never both. */
  EXP_ID = 'afe-experience-id',
  /** When calling api's we must send an experience id or a partner id, never both. */
  PARTNER_ID = 'afe-partner-id',
  /* Dal api Sapi target version */
  SAPI_TARGET = 'afe-sapi-target',
  SESSION_ID = 'afe-session-id',
  SOURCE_ID = 'afe-source-id',
  TRACE_ID = 'afe-trace-id',
  TRANSACTION_ID = 'afe-transaction-id',
  USER_TOKEN = 'afe-user-token',
}
