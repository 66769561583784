export const moduleName = 'flags';

// TODO Set up app specific flags with ECP-2347
export enum FeatureFlags {
  DISABLE_VEHICLE_SERVICE_CALL = 'DISABLE_VEHICLE_SERVICE_CALL',

  /** ECP-11497 Allow prefill short flow auto */
  ALLOW_PREFILL_SHORT_FLOW_AUTO = 'ALLOW_PREFILL_SHORT_FLOW_AUTO',
  /** ECP-700: Allow prefill overrides for home/auto */
  FORCE_LONG_FLOW_HOME = 'FORCE_LONG_FLOW_HOME',

  // !TODO Remove after switching to unauth SAPI
  DISABLE_AUTH0 = 'DISABLE_AUTH0',
  /** Feature flag for telematics product from GM. Added in: https://theexperimentationlab.atlassian.net/browse/CSUI-758 */
  TELEMATICS = 'TELEMATICS',
  /** Feature flag for GM Employee discount */
  MI_EMPLOYEE_DISCOUNT = 'MI_EMPLOYEE_DISCOUNT',

  /** Feature flag for driver license confirmation question. Added in: https://theexperimentationlab.atlassian.net/browse/CSUI-996 */
  PNIDL_NUMBER_CONFIRMATION = 'PNIDL_NUMBER_CONFIRMATION',
  /** Feature flag for showing / hiding toggle for home monthly payments. Added in: https://bitbucket.org/p20/customersales-ui/pull-requests/1021 */
  HOME_MONTHLY_PREMIUM = 'HOME_MONTHLY_PREMIUM',
  /** Feature flag for showing / hiding toggle for renters monthly payments on pages prior to Checkout.*/
  RENTERS_MONTHLY_PREMIUM = 'RENTERS_MONTHLY_PREMIUM',
  /** EDSP-15563 - Feature flag to hide more insurance option on landing. */
  HIDE_MORE_INSURANCE_OPTIONS = 'HIDE_MORE_INSURANCE_OPTIONS',

  /** Whether to show an outage banner or not */
  OUTAGE_NOTICE = 'OUTAGE_NOTICE',
  /** ECP-2434 Disable email quotes link for amfam-excl partner */
  DISABLE_EMAIL_QUOTES = 'DISABLE_EMAIL_QUOTES',
  /** Enable agent genesys cloud recording */
  AGENT_GENESYS_CLOUD_RECORDING = 'AGENT_GENESYS_CLOUD_RECORDING',
  /** ECP-11896 dependent on email team */
  AGENT_REPLY_TO_EMAIL = 'AGENT_REPLY_TO_EMAIL',
  /** ECP-10371 Enable Agent Call Recording */
  AGENT_CALL_RECORDING = 'AGENT_CALL_RECORDING',
  /** ECP-10586 Hide Product Selection on the Retrieve Page */
  HIDE_RETRIEVE_PRODUCT_QUESTION = 'HIDE_RETRIEVE_PRODUCT_QUESTION',
  /** ECP-3872 Enable Additional Interest Questions */
  ADDITIONAL_INTEREST = 'ADDITIONAL_INTEREST',
  PARTNER_SITE_UNAVAILABLE = 'PARTNER_SITE_UNAVAILABLE',
  /** ECP-8552 Feature flag for Select Product Page */
  SELECT_PRODUCT_PAGE = 'SELECT_PRODUCT_PAGE',
  /** ECP-12048 New iQuote Coverages for Moonshot */
  IQUOTE_HO3_COVERAGES = 'IQUOTE_HO3_COVERAGES',
  IQUOTE_HO4_COVERAGES = 'IQUOTE_HO4_COVERAGES',
  /** SNI PAGE */
  SECONDARY_NAMED_INSURED_PAGE = 'SECONDARY_NAMED_INSURED_PAGE',
  /** EDSP-8638 SNI questions on driver profile page  */
  SNI_DRIVER_QUESTIONS = 'SNI_DRIVER_QUESTIONS',
  /** TPI PAGE */
  THIRD_PARTY_INTEREST_FLOW = 'THIRD_PARTY_INTEREST_FLOW',
  /** Third Party Reports PAGE */
  THIRD_PARTY_REPORTS_FLOW = 'THIRD_PARTY_REPORTS_FLOW',
  /** feature falg to enable CA Wildfire feature */
  CA_WILDFIRE_MANDATE = 'CA_WILDFIRE_MANDATE',
  /** feature flag to enable CO Wildfire feature */
  CO_WILDFIRE_LEGISLATION = 'CO_WILDFIRE_LEGISLATION',
  /** Renters property details Page */
  RENTERS_PROPERTY_DETAILS_PAGE = 'RENTERS_PROPERTY_DETAILS_PAGE',
  /** ECP-12775 Agent cross acount feature */
  AGENT_CROSS_ACCOUNT_RECALL = 'AGENT_CROSS_ACCOUNT_RECALL',
  /** ECP-14241 Consumers Product Select cross sell */
  PRODUCT_SELECT_REDESIGN = 'PRODUCT_SELECT_REDESIGN',
  /** ECP-13363 Consumers Product Select cross sell */
  PRODUCT_SELECT_CROSS_SELL = 'PRODUCT_SELECT_CROSS_SELL',
  /** ECP-13346 Consumers Post bind cross sell */
  POST_BIND_CROSS_SELL = 'POST_BIND_CROSS_SELL',
  /** ECP-13224 toggle auto max policy start date for CA/WA */
  AUTO_QUOTE_WINDOW_TO_30DAYS = 'AUTO_QUOTE_WINDOW_TO_30DAYS',
  /** feature flag to enable checkout page redesign for payment section */
  CHECKOUT_PAGE_REDESIGN = 'CHECKOUT_PAGE_REDESIGN',
  /** feature flag to enable checkout page redesign for contact information section */
  CHECKOUT_CONTACT_INFORMATION = 'CHECKOUT_CONTACT_INFORMATION',
  /** Feature flag to enable CAPI 10 day review modal popup*/
  CAPI_10_DAY_REVIEW = 'CAPI_10_DAY_REVIEW',
  /** ECP-12430 feature flag to enable session timeout view on widget */
  SESSION_TIMEOUT = 'SESSION_TIMEOUT',
  /** ECP-12466 ABC test for save and exit experiment  */
  SAVE_AND_EXIT = 'SAVE_AND_EXIT',
  /** ECP-14853 Zillow Embedded Module express path */
  EXPRESS_PATH = 'EXPRESS_PATH',
  /** Zillow Hackathon to enable ECP in an iframe */
  IFRAME_EXPERIENCE = 'IFRAME_EXPERIENCE',
  /** ECP-14954 Real Time Credit Card */
  REAL_TIME_CREDIT_CARD_PAYMENT = 'REAL_TIME_CREDIT_CARD_PAYMENT',
  /** EDSP-999 Costco-Midvale participating insurers addition and footer swap */
  AUTO_PRODUCT_SWAP = 'AUTO_PRODUCT_SWAP',
  /** feature flag AUTO_PRODUCT_SWAP_STATE_ROLLOUT */
  AUTO_PRODUCT_SWAP_STATE_ROLLOUT = 'AUTO_PRODUCT_SWAP_STATE_ROLLOUT',
  /** EDSP-105 Consumer Edit Home Prefill Summary Page | A/B/C Test | Costco H03 */
  HOME_PREFILL_SUMMARY_PAGE_ABC_TEST = 'HOME_PREFILL_SUMMARY_PAGE_ABC_TEST',
  /** EDSP-12641  CONNECT AUTO | Create Feature Flag for minimum limits increase warning for coverage page in CA, UT, and VA*/
  CONNECT_AUTO_MINIMUM_LIMITS_WARNING = 'CONNECT_AUTO_MINIMUM_LIMITS_WARNING',
  /** EDSP-14803  Introduce retries for calls that fail with status 0 */
  CUSTOM_FETCH_RETRY_TIMEOUT = 'CUSTOM_FETCH_RETRY_TIMEOUT',
  /** EDSP-16026 Enable Year field on Delta page */
  DELTA_YEAR_ENABLED = 'DELTA_YEAR_ENABLED',
  /** EDSP-14868 Log Agent Token */
  ENABLE_LOG_AGENT_TOKEN = 'ENABLE_LOG_AGENT_TOKEN',
  /** EDSP-15564 - feature flag to redirect user to PNI page if product and zip code in the URL */
  REDIRECT_TO_PNI = 'REDIRECT_TO_PNI',
  /** EDSP-15611 - Feature flag to fetch config api flows from local library */
  ENABLE_CONFIG_LIBRARY = 'ENABLE_CONFIG_LIBRARY',
  /** EDSP-16205 Agent Dashboard Redesign */
  AGENT_DASHBOARD_REDESIGN = 'AGENT_DASHBOARD_REDESIGN',
  /** EDSP-16140 Reduce duplicate token refreshes */
  REDUCE_DUPLICATE_REFRESH = 'REDUCE_DUPLICATE_REFRESH',
  /** EDSP-19507 Children's Miracle Network Campaign
   * children miracle network campaign during April 25-May 31, 2025
   * this ff can be removed after the campaign
   */
  CHILDREN_MIRACLE_NETWORK_CAMPAIGN = 'CHILDREN_MIRACLE_NETWORK_CAMPAIGN',

  // ---- start advance feature keys ----
  /** ADVECP-3582 Generate token for EFT pay */
  EFT_PAY_THROUGH_PAYMENT_GATEWAY = 'EFT_PAY_THROUGH_PAYMENT_GATEWAY',
  /** ADVECP This to do additional save to API before offers */
  PROOF_DRAFT_ENABLED = 'PROOF_DRAFT_ENABLED',
  /** ADVECP-4010 Advance specific This is to define if PROOF DRAFT should be called customizing coverages before calling put offers */
  SHOULD_PROOF_DRAFT_CUSTOMIZE_COVERAGES = 'SHOULD_PROOF_DRAFT_CUSTOMIZE_COVERAGES',
  /** ADVECP-4010 Advance specific This is to define offers call when to rate and when to not rate */
  OFFER_WITH_AND_WITHOUT_RATE_FEATURE = 'OFFER_WITH_AND_WITHOUT_RATE_FEATURE',
  /** EDSP-11441 Advance specific to skip quote page on back nav and when offer is in bindable state */
  SHOULD_SKIP_QUOTE_PAGE_ON_BACK_NAV = 'SHOULD_SKIP_QUOTE_PAGE_ON_BACK_NAV',
  /** EDSP-11441 Advance specific should call prefill every time a product is added */
  SHOULD_CALL_PREFILL_ON_ADDING_PRODUCT = 'SHOULD_CALL_PREFILL_ON_ADDING_PRODUCT',
  /** EDSP-15963 Determines to look for errors[] node on hhtp success calls */
  ERROR_HANDLING_REDESIGN = 'ERROR_HANDLING_REDESIGN',
  // ---- end advance feature keys ----

  // ---- start servicing enable feature keys ----
  // features where we want the default to be "Feature OFF"
  // generally these are NEW features that have NOT been verified in production in prior release
  //* * feature flag enabling add driver */
  ADD_DRIVER = 'ADD_DRIVER',
  //* * feature flag enabling add vehicle */
  ADD_VEHICLE = 'ADD_VEHICLE',
  //* * feature flag enabling additional interest edit features*/
  ADDITIONAL_INTEREST_MANAGE = 'ADDITIONAL_INTEREST_MANAGE',
  //* * feature flag enabling additional interest section*/
  ADDITIONAL_INTEREST_SECTION = 'ADDITIONAL_INTEREST_SECTION',
  /** feature flag for enabling autopay for classic */
  AUTOPAY_CLASSIC = 'AUTOPAY_CLASSIC',
  /** feature flag to enable book-rolling feature */
  BOOKROLL = 'BOOKROLL',
  /** feature flag enabling CA Mileage */
  CA_MILEAGE = 'CA_MILEAGE',
  /** feature flag enabling autopay indicator for classic policies */
  CLASSIC_AUTOPAY_INDICATOR = 'CLASSIC_AUTOPAY_INDICATOR',
  /** feature flag enabling coverages for classic auto policies */
  CLASSIC_COVERAGES_AUTO = 'CLASSIC_COVERAGES_AUTO',
  /** feature flag enabling coverages for classic home policies */
  CLASSIC_COVERAGES_HOME = 'CLASSIC_COVERAGES_HOME',
  /** feature flag enabling edit vin for classic policies */
  EDIT_VIN_CLASSIC = 'EDIT_VIN_CLASSIC',
  /** feature flag to enable the logging of all servicing requests to DataDog */
  LOG_ALL_SERVICING_REQUESTS = 'LOG_ALL_SERVICING_REQUESTS',
  /** feature flag enabling membership section */
  MEMBERSHIP = 'MEMBERSHIP',
  /** feature flag to show paperless */
  PAPERLESS = 'PAPERLESS',
  /** feature flag to show legacy connect policies paperless */
  PAPERLESS_CLASSIC = 'PAPERLESS_CLASSIC',
  /** feature flag to enable WorldPay feature for connect policies */
  PAYMENTS_CLASSIC = 'PAYMENTS_CLASSIC',
  // ---- end servicing enable feature keys ----

  // ---- start servicing disable feature keys ----
  // features where we want the default to be "Feature ON"
  // generally these are features that have been verified in production in prior release
  /** feature flag for disabling agent app */
  DISABLE_AGENT_APP = 'DISABLE_AGENT_APP',
  /** feature flag for disabling autopay */
  DISABLE_AUTOPAY = 'DISABLE_AUTOPAY',
  /** feature flag for disabling all auth routes - login/create/reset */
  DISABLE_AUTH_FLOW = 'DISABLE_AUTH_FLOW',
  /** feature flag disabling the claims iframe */
  DISABLE_CLAIMS_PAGE = 'DISABLE_CLAIMS_PAGE',
  /** circuit breaker to turn off Dal analytics if something wrong with integration */
  DISABLE_DAL_ANALYTICS = 'DISABLE_DAL_ANALYTICS',
  /** disables classic documents */
  DISABLE_DOCUMENTS_CLASSIC = 'DISABLE_DOCUMENTS_CLASSIC',
  /** disables payments */
  DISABLE_PAYMENTS = 'DISABLE_PAYMENTS',
  /** feature flag for disabling agent app */
  DISABLE_SERVICING_APP = 'DISABLE_SERVICING_APP',
  // ---- end servicing disable feature keys ----
}

export const FlagVariables = {
  [FeatureFlags.OUTAGE_NOTICE]: { OUTAGE_TEXT: 'OUTAGE_TEXT' },
  [FeatureFlags.POST_BIND_CROSS_SELL]: {
    POST_BIND_CROSS_SELL_DISCLAIMER_TEXT: 'POST_BIND_CROSS_SELL_DISCLAIMER_TEXT',
    LIFE_HEADER: 'LIFE_HEADER',
    LIFE_TEXT: 'LIFE_TEXT',
    LIFE_TEXT2: 'LIFE_TEXT2',
    LIFE_BUTTON_NAME: 'LIFE_BUTTON_NAME',
    LIFE_BUTTON_LINK: 'LIFE_BUTTON_LINK',
    PET_HEADER: 'PET_HEADER',
    PET_TEXT: 'PET_TEXT',
    PET_TEXT2: 'PET_TEXT2',
    PET_BUTTON_NAME: 'PET_BUTTON_NAME',
    PET_BUTTON_LINK: 'PET_BUTTON_LINK',
  },
  [FeatureFlags.IFRAME_EXPERIENCE]: { ACCEPTED_ORIGINS: 'ACCEPTED_ORIGINS' },

  [FeatureFlags.CONNECT_AUTO_MINIMUM_LIMITS_WARNING]: {
    CONNECT_AUTO_LIMIT_DATE: 'CONNECT_AUTO_LIMIT_DATE',
  },

  [FeatureFlags.CO_WILDFIRE_LEGISLATION]: {
    PAYMENT_ADDITIONAL_MESSAGE: 'PAYMENT_ADDITIONAL_MESSAGE',
  },
} as const;

export const enum Events {
  AUTO_DELTA_PAGE = 'reach auto delta page',
  AUTO_DISCOUNTS_PAGE = 'reach auto discounts page',
  AUTO_SUMMARY_PAGE = 'reach auto summary page',
  CHECKOUT_PAGE = 'reach checkout page',
  DRIVER_PAGE = 'reach driver page',
  HOME_DISCOUNTS_PAGE = 'reach home discounts page',
  PERSONAL_PAGE = 'reach personal info page',
  PERSONAL_PAGE_2 = 'reach personal info page 2',
  POSTBIND_PAGE = 'reach postbind page',
  QUOTE_SUMMARY_PAGE = 'reach quote summary page',
  VEHICLE_PAGE = 'reach vehicle page',
  RENTERS_DISCOUNTS_PAGE = 'reach renters discounts page',
  LANDING_PAGE = 'reach landing page',
  MEMBERSHIP_PAGE = 'reach membership page',
  PARTNER_SITE_UNAVAILABLE_PAGE = 'reach site unavailable page',
  COVERAGES_PAGE = 'reach coverages page',
  SECONDARY_NAMED_INSURED_PAGE = 'reach secondary named insured page',
}
