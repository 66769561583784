import { Children, cloneElement, forwardRef, isValidElement, memo, useCallback } from 'react';

import type { RadioGroupProps as MuiRadioGroupProps } from '@mui/material';
import { FormControl, FormHelperText, FormLabel, RadioGroup as MuiRadioGroup } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { hasValue } from '@ecp/utils/common';

import { AutoAdjustableGrid } from '@ecp/components';
import type { AnswerValue } from '@ecp/types';

import type { AutoAdjustableGridProps } from '../AutoAdjustableGrid';
import { useStyles } from './RadioGroup.styles';

export interface RadioGroupProps extends MuiRadioGroupProps {
  cardSize?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  error?: string;
  helperText?: React.ReactNode;
  label?: React.ReactNode;
  variant?:
    | 'classic'
    | 'yesNoButton'
    | 'radioCard'
    | 'yesNoRadioCard'
    | 'largeRadioCard'
    | 'buttonCard'
    | 'buttonCardWithRadioButton'
    | 'roundButton'
    | 'card'
    | 'button'
    | 'horizontalCard';
  adjustableGrid?: boolean;
  adjustableGridProps?: AutoAdjustableGridProps;
  trackingName?: string;
  trackingLabel?: string;
  actionOnChange?(value: AnswerValue): void;
  actionOnComplete?(value: AnswerValue): void;
}

export const RadioGroup: React.FC<RadioGroupProps> = memo(
  forwardRef<unknown, RadioGroupProps>((props, ref) => {
    const {
      className,
      disabled,
      error,
      helperText,
      label,
      name,
      value: valueProp = '',
      trackingName,
      trackingLabel,
      actionOnComplete,
      actionOnChange,
      variant = 'button',
      children,
      adjustableGrid = false,
      adjustableGridProps,
      cardSize,
      ...radioGroupProps
    } = props;

    const value = hasValue(valueProp) ? String(valueProp) : '';
    const radioGroupChildren = Children.map(children, (child) => {
      if (!isValidElement(child)) return null;
      const checked = child.props.value == null ? undefined : child.props.value === value;
      const commonProps = { disabled, variant, checked };

      return cloneElement(child, commonProps);
    });
    const { classes, cx } = useStyles({ ...props, cardCount: Children.count(radioGroupChildren) });

    const handleChange: MuiRadioGroupProps['onChange'] = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue: AnswerValue = event.target.value;
        newValue = variant === 'yesNoButton' ? newValue === 'true' : newValue;
        actionOnChange?.(newValue);
        actionOnComplete?.(newValue);
        if (trackingName) {
          trackClick({ action: trackingName, label: trackingLabel || event.target.value });
        }
      },
      [actionOnComplete, actionOnChange, trackingLabel, trackingName, variant],
    );

    const isClassic =
      variant === 'classic' || variant === 'radioCard' || variant === 'largeRadioCard';

    return (
      <FormControl
        component='fieldset'
        className={cx(className, classes.formControl)}
        disabled={disabled}
        error={!!error}
      >
        <FormLabel
          component='legend'
          focused={false}
          className={cx(classes.label, variant === 'largeRadioCard' && classes.largeRadioLabel)}
          id={name && `${name}-label`}
          disabled={disabled}
        >
          {label || name}
          {helperText && <FormHelperText error={false}>{helperText}</FormHelperText>}
        </FormLabel>
        <MuiRadioGroup
          row={!isClassic}
          ref={ref}
          {...radioGroupProps}
          name={name}
          classes={{
            root: cx(classes.group, cardSize && classes[cardSize]),
          }}
          value={value}
          onChange={handleChange}
        >
          {adjustableGrid ? (
            <AutoAdjustableGrid spacing={10} {...adjustableGridProps}>
              {radioGroupChildren}
            </AutoAdjustableGrid>
          ) : (
            radioGroupChildren
          )}
        </MuiRadioGroup>
        {error && (
          <FormHelperText
            className={cx(variant === 'buttonCard' && classes.errorText)}
            role='alert'
          >
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }),
);
