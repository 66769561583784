import { memo } from 'react';

import type { GridProps as MuiGridProps } from '@mui/material';
import { Grid as MuiGrid } from '@mui/material';

import { useStyles } from './GridItem.styles';

type SpacingMapping = Record<'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;

const spacingMapping: SpacingMapping = {
  none: 0,
  xs: 15,
  sm: 20,
  md: 30,
  lg: 40,
  xl: 60,
};

export type GridProps = Omit<
  MuiGridProps,
  'rowSpacing' | 'columnSpacing' | 'spacing' | 'container' | 'item'
> & {
  mapping?: SpacingMapping;
  topSpacing?: keyof SpacingMapping | number;
  bottomSpacing?: keyof SpacingMapping | number;
  // defaults to true
  show?: boolean;
};

export const GridItem: React.FC<GridProps> = memo((props) => {
  const {
    className,
    children,
    mapping = spacingMapping,
    topSpacing = 'none',
    bottomSpacing = 'none',
    show = true,
    ...rest
  } = props;
  const marginTop = typeof topSpacing === 'number' ? topSpacing : mapping[topSpacing];
  const marginBottom = typeof bottomSpacing === 'number' ? bottomSpacing : mapping[bottomSpacing];
  const { classes, cx } = useStyles({ marginTop, marginBottom });

  if (!show) return null;

  return (
    <MuiGrid {...rest} item container={false} className={cx(classes.root, className)}>
      {children}
    </MuiGrid>
  );
});
