/** Immutable empty array. Use it as a default immutable value instead of undefined and null where an array is expected.
 *  It is helpful to prevent unnecessary re-renders when e.g. a default value for React component prop needs to be an array. */
export const emptyArray = Object.freeze([]);

/**
 * A temporary replacement for Array.includes and ReadonlyArray.includes
 * while TypeScript doesn't accept searchElement to be a superset of an array elements, but it should.
 * Lodash `includes` does accept, but doesn't serve as a guard clause since isn't correctly typed.
 *
 * @see https://github.com/microsoft/TypeScript/issues/26255
 * @see https://github.com/microsoft/TypeScript/issues/14520 - tracks implementation
 */
export const includes = <T extends U, U>(
  array: ReadonlyArray<T> | Array<T>,
  searchElement: U,
  fromIndex?: number,
): searchElement is T => array.includes(searchElement as T, fromIndex);

/**
 * Replacement for lodash uniq method
 * @param arr - array to filter out duplicates from
 */
export const unique = <T>(arr: T[]): T[] => [...new Set(arr)];

export const uniqueBy = <T>(values: T[], iteratee: (value: T) => string): T[] => {
  const obj = values.reduce((acc, value) => {
    const key = iteratee(value);
    acc[key] = value;

    return acc;
  }, {} as Record<string, T>);

  return Object.values(obj);
};

export const isStringArray = (a: unknown): a is string[] =>
  !!a && Array.isArray(a) && (!a.length || typeof a[0] === 'string');

export const ensureStringArray = (a: unknown): string[] => {
  if (a === null || a === undefined) {
    return [];
  }
  if (!isStringArray(a)) {
    throw new Error(`argument given was not a string array - ${a}`);
  }

  return a;
};
